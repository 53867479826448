$body-color: #111111;

$primary: darken(#2f92ff, 13%);
$dark: #193c5a;
$link-color: #006ec3;
$jumbotron-bg: #f6f6fa;

@import '~bootstrap/scss/bootstrap.scss';

.pr-6 {
  padding-right: 4rem !important;
}
