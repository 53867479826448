body,
html,
#root {
    height: 100%;
}

body {
    overflow-y: scroll;
}

body.no-scroll {
    overflow-y: hidden;
}

.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.page-content {
    flex-grow: 10;
    padding-top: 24px;
    padding-bottom: 48px;
    margin-top: 60px;
}

.footer {
    width: 100%;
    flex-grow: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 2;
}

.submit-button-check-mark {
    display: inline-block;
    transition: all 150ms;
    margin-right: 4px;
    overflow: hidden;
    vertical-align: bottom;
}

.submit-button-check-mark.submit-button-hidden-check-mark {
    width: 0;
    border: 0;
    margin-right: 0;
}

.button-spinner.spinner-border-sm {
    transition: all 150ms;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.button-spinner-hidden.spinner-border-sm {
    width: 0;
    border: 0;
    margin-right: 0;
}

.text-pre-line {
    white-space: pre-line;
}

.file-dropzone-wrapper {
    position: relative;
}

.file-dropzone-overlay {
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: #b4dcff 1px dotted;
    background: rgba(207, 228, 255, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-dropzone {
    padding: 24px;
    border: #006ec3 2px dashed;
}

.file-dropzone-active {
    background: #daeafc;
}

.paper-page-preview-filename {
    overflow-wrap: break-word;
}

.paper-full-view-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-y: scroll;
    overflow-x: auto;

    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    backdrop-filter: blur(10px);
}

.paper-full-view-page {
    user-select: none;
    width: 100%;
    height: 100%;
}

.paper-full-view-page-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}

.paper-full-view-next-button {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
    z-index: 10001;
}

.paper-full-view-prev-button {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 32px;
    z-index: 10001;
}

.paper-full-view-prev-button svg, .paper-full-view-next-button svg {
    position: relative;
    top: -2px;
}

.paper-full-view-close-button {
    position: fixed;
    top: 32px;
    right: 32px;
    z-index: 10001;
}

.paper-full-view-close-button span {
    transform: scale(1.5);
    transform-origin: center;
    display: inline-block;
    position: relative;
    top: -2px;
}

@media (max-width: 680px) {
    .paper-full-view-next-button {
        position: fixed;
        top: auto;
        bottom: 16px;
        right: auto;
        left: 50%;
        transform: translateX(16px);
    }

    .paper-full-view-prev-button {
        position: fixed;
        top: auto;
        bottom: 16px;
        left: auto;
        right: 50%;
        transform: translateX(-16px);
    }
}

.online-round-question-empty {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.online-round-question-bubble {
    padding: 16px;
    white-space: pre-wrap;
    border-radius: 2px;
    margin: 1rem;
}

.online-round-question-bubble-me {
    background: #f1f7ff;
    margin-left: 2rem;
}

.online-round-question-bubble-them {
    background: #f7f7f7;
    margin-right: 2rem;
}

.question-answer-attach-button-compact {
    position: absolute;
    right: 0;
    top: -2px;
}

.question-answer-attach-icon {
    position: relative;
    top: -2px;
}

@media (min-width: 768px) {
    .online-round-question-bubble-me {
        margin-left: 4rem;
    }

    .online-round-question-bubble-them {
        margin-right: 4rem;
    }
}

.tournament-questions-table-text {
    white-space: pre-wrap;
    padding-bottom: 1rem !important;
}

.pseudo-link {
    color: #006ec3;
    cursor: pointer;
}

.pseudo-link:hover {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #006ec3;
}

.add-user-name {
    min-width: 300px;
}

.add-user-email {
    min-width: 300px;
}

.paper-flag-checkbox {
    padding-top: 8px;
    padding-bottom: 8px;
}

.paper-flag-checkbox .form-check > * {
    cursor: pointer;
}

.paper-flag-ok {
    background-color: rgba(33, 188, 67, 0.3);
}

.paper-flag-warning {
    background-color: rgba(255, 193, 7, 0.5);
}

.paper-flag-danger {
    background-color: rgba(219, 0, 23, 0.3);
}

.users-table-name {
    cursor: pointer;
}

.venue-papers-participant-row-marks {
    white-space: pre-wrap;
}

.criteria strong.plus-minus {
    font-size: 1.25em;
}

.criteria strong.plus-halved {
    position: relative;
    left: -2px;
}

.batch-almost-finished {
    background-color: rgba(33, 188, 67, 0.1);
}

.batch-finished {
    background-color: rgba(33, 188, 67, 0.3);
}

.problem-image {
    width: 100%;
    max-width: 200px;
}

@media (min-width: 768px) {
    .problem-image {
        position: relative;
        top: -24px;
    }
}

@media (max-width: 768px) {
    .problem-image {
        margin: 1rem auto;
        display: block;
    }
}

.uploaded-paper-file {
    padding: 1rem;
    border: 1px dashed #006ec3;
    cursor: grab;
    background: #e4f1ff;
}

.uploaded-paper-file-dragging {
    cursor: grabbing;
    opacity: 0.95;
}

.venue-upload-participant-edit-button {
    opacity: 0;
    position: relative;
    top: -2px;
    transition: opacity 150ms;
}

tr:hover .venue-upload-participant-edit-button {
    opacity: 1;
}

.venue-upload-paper-drop-target {
    min-height: 40px;
    min-width: 160px;
    margin: -8px;
    text-align: center;
    line-height: 36px;
}

.venue-upload-paper-drop-target.venue-upload-paper-drop-target-can-drop {
    background-color: #c4f8d1;
    border: 1px dashed #1e7e34;
}

.venue-upload-add-participant-grade {
    max-width: 128px;
}

.batch-page-examinations-table-cell {
    width: 33.33%;
}

.examination-marks-not-completed {
    background-color: rgba(255, 193, 7, 0.2);
}

.page-actions-buttons {
    background: white;
    position: sticky;
    top: 56px;
    z-index: 10;
    margin: 0;
    padding: 1rem 0;
}

.btn-sm.school-info-edit-button {
    opacity: 0;
    transition: opacity 150ms ease-in;
    padding: 0 0.25rem;
    position: relative;
    top: -2px;
}

tr:hover .school-info-edit-button {
    opacity: 1;
}

.table-striped tbody tr.school-info-looks-pretty {
    background-color: rgba(64, 255, 64, 0.1);
}

.table-striped tbody tr.school-info-looks-pretty:nth-of-type(odd) {
    background-color: rgba(0, 196, 0, 0.1);
}

.cursor-pointer {
    cursor: pointer;
}
