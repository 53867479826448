.results-input-marks-table {
    width: auto;
    margin-top: -8px;
}

.results-input-marks-table td {
    padding: 1px;
    border: none;
}

.results-input-mark-input {
    width: 32px;
    font-size: 14px;
    padding: 4px 4px 6px 4px;
    line-height: 1;
    height: 28px;
}

.results-input-mark-input.form-control.is-invalid {
    background-image: none;
    padding-right: 4px;
}

.results-input-selected-participant > td {
    background: #eaf6ff;
}

.result-input-hint {
    position: sticky;
    bottom: 0;
    background: white;
    margin-top: 32px;
    padding-bottom: 8px;
    border-top: 1px solid #dee2e6;
}

.results-input-participant-edit-link {
    opacity: 0;
    transition: opacity 150ms ease-in;
    padding: 0 0.25rem;
    position: relative;
    top: -2px;
}

tr:hover .results-input-participant-edit-link {
    opacity: 1;
}

.results-input-papers-table th:first-child {
    width: 80px;
}

.results-input-papers-table th:last-child {
    min-width: 420px;
}
