.table.results-page-paper-table {
    max-width: 600px;
    margin-bottom: 16px;
    margin-left: 16px;
}

.table.results-page-paper-table {
    > thead > tr > th {
        border-bottom: none;
        font-weight: normal;
        padding: 2px 6px 1px 6px;
        width: 6%;
        &:first-child {
            width: 38%;
        }

        &.results-page-paper-short-header:first-child {
            width: 20%;
        }

        &:last-child {
            width: auto;
        }
    }

    > tbody > tr > td {
        border-top: none;
        padding: 2px 6px 1px 6px;
    }
}

.results-page-participant-data {
    font-size: 14px;
    font-weight: normal;
    margin-left: 20px;
}

.results-page-participant-result {
    font-weight: bold;
    margin-bottom: 36px;
    line-height: 2;
}
