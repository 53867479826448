.examination-paper-view-page-preview {
    padding: 0.25rem;
    border: 1px solid #aaaaaa;
    border-radius: 2px;
    box-sizing: content-box;
    margin-bottom: 4px;
    cursor: pointer;
}

.examination-paper-view-page-preview img {
    vertical-align: initial;
}

.examination-paper-view-page-buttons svg {
    position: relative;
    top: -1px;
}
