.examination-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    backdrop-filter: blur(10px);

    opacity: 0;
    transition: opacity 300ms ease-out;
}

.examination-modal-backdrop-in {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.examination-modal-content {
    display: flex;
    height: 100%;
}

.examination-modal-paper {
    overflow-y: scroll;
    overflow-x: auto;
    max-height: 100vh;
    flex-grow: 1;
    outline: 0;
}

.examination-modal-paper:focus-visible {
    outline: 1px solid #333333;
}

.examination-modal-problem-header {
    margin: 32px auto 16px auto;
    position: relative;
    left: -16px;
    color: #efefef;
}

.examination-modal-page {
    margin: 16px auto 32px auto;
    user-select: none;
}

.examination-modal-page-rotate-button {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #444444;
    z-index: 10001;
    display: block;
    transition: color 50ms, opacity 200ms;
    padding: 4px 8px;
    border: 0;
    background: transparent;
    outline: none;
    opacity: 0;
}

.examination-modal-page:hover .examination-modal-page-rotate-button {
    opacity: 1;
}

.examination-modal-page-rotate-button:hover {
    color: #111111;
}

.examination-modal-page.drawing-tool-active {
    cursor: crosshair;
}

.examination-modal-page.eraser-active {
    polyline:hover {
        opacity: 0.75;
        cursor: pointer;
    }

    .text-comment:hover {
        opacity: 0.75;
        cursor: pointer;
    }
}

.examination-modal-page-comments {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.text-comment-click-away {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3100;
}

.text-comment-click-away + .text-comment {
    z-index: 3200;
}

.text-comment {
    position: absolute;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 256px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(4px);
    box-shadow: 0 0 4px 1px rgba(64, 64, 64, 0.5);
    text-align: left;
    box-sizing: border-box;

    textarea {
        display: block;
        border: 1px solid #ccc;
        width: 240px;
        background: transparent;
        outline: 0;
    }
}

.text-comment.text-comment-saved {
    width: 16px;
    height: 16px;
    box-shadow: none;
    background: none;
    user-select: text;
    color: transparent;
}

.text-comment.text-comment-saved .text-comment-content {
    display: none;
}

.text-comment:after {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.65);
    width: 24px;
    height: 24px;
}

.text-comment.text-comment-saved:hover {
    max-width: 256px;
    width: auto;
    height: auto;
    padding: 16px;
    box-shadow: 0 0 4px 1px rgba(64, 64, 64, 0.5);
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(4px);
    cursor: default;
    color: #111111;
}

.text-comment.text-comment-saved:hover .text-comment-content {
    display: block;
}

.examination-modal-sidebar-placeholder {
    min-width: 48px;
    background: #202020;
    transition: min-width 200ms;
}

.examination-modal-sidebar-placeholder.sidebar-open {
    min-width: 240px;
}

.examination-modal-sidebar {
    position: absolute;
    width: 241px;
    left: -193px;
    transition: left 200ms;
}

.examination-modal-sidebar-placeholder.sidebar-open .examination-modal-sidebar {
    left: 0;
}

.examination-modal-sidebar-marks {
    position: absolute;
    top: 0;
    left: 0;
    width: 192px;
    padding: 16px 12px;
}

.examination-modal-sidebar-tools {
    position: absolute;
    top: 0;
    right: 0;
    width: 49px;
    padding: 8px 4px;
    border-left: 1px solid #444444;

    .tool {
        display: block;
        color: #efefef;
        transition: color 50ms;
        margin: 0 0 16px 0;
        padding: 8px;
        border: 0;
        background: transparent;
        outline: none;
    }

    .tool:hover {
        color: #ffffff;
    }

    .tool:focus-visible {
        outline: #efefef solid 1px;
    }

    .tool.tool-selected {
        background-color: #fefefe;
        color: #444444;
    }

    .tool.tool-selected:hover {
        color: #000000;
    }

    .tool[disabled] {
        color: #999999;
    }

    .tool-divider {
        height: 1px;
        background-color: #444444;
        margin: -8px 0 8px 0;
    }
}

.examination-modal-close-button {
    position: fixed;
    top: 16px;
    right: 36px;
    color: #efefef;
    z-index: 10001;
    display: block;
    transition: color 50ms;
    padding: 0 12px;
    border: 0;
    background: transparent;
    outline: none;
    font-size: 32px;
}

.examination-modal-close-button:hover {
    color: #ffffff;
}

.examination-modal-sidebar-show-icon {
    transform: rotate(0deg);
    transition: transform 300ms ease-in-out;
}

.examination-modal-sidebar-show-icon-open {
    transform: rotate(180deg);
}

.paper-examination-sidebar .problemLabel {
    color: #efefef;
    margin-top: 2px;
    margin-bottom: 0;
    display: block;
}

.paper-examination-sidebar .form-control {
    color: #efefef;
    background-color: #444444;
    border-color: #000000;
}

.paper-examination-sidebar .form-control:focus {
    color: #efefef;
    background-color: #444444;
    border-color: #111111;
}

.examination-modal-form-go-to-button {
    display: inline-block;
    position: relative;
    top: -2px;
    color: #efefef;
    transition: color 50ms;
    margin: 0 0 0 2px;
    border: 0;
    background: transparent;
    outline: none;
}

.examination-modal-form-go-to-button:focus {
    outline: 0;
}

.examination-modal-form-go-to-button:hover {
    color: #ffffff;
}

.examination-modal-problem-modal-backdrop {
    z-index: 11040;
}

.examination-modal-problem-modal {
    z-index: 11050;
}
